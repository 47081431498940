body,
.nav-link {
    font-size: .9rem !important;
}

/* Add a star after the label for form fields with the "required" class */

a:hover {
    text-decoration: none;
}

label.required::after {
    content: ' *';
    color: red;
}

.invalid-feedback {
    display: block;
    color: red !important;
}


.radius-none-card {
    --bs-card-border-radius: 0 !important;
}


.custom-nav-tabs .nav-link {
    border: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    color: #000000;
}

.custom-nav-tabs .nav-link:hover {
    color: #000000 !important;
}


.custom-tab.active {
    border-bottom: 2px solid #1B99BD !important;
}

.nav-link.active {
    border-bottom: 2px solid #1B99BD !important;
}

.custom-nav.nav {
    --bs-nav-link-padding-y: 5px;
}

.map-boxes {
    max-height: auto;
}

.nav-link.active {
    border-bottom-width: 1px !important;
}

@media only screen and (max-width: 768px) {

    .map-boxes {
        max-height: 200px;
        overflow: auto;

    }
}


.container {
    width: 100%;
    margin: 0 auto;
}

.form-control-solid {
    background-color: #F9F9F9 !important;
}

.fs-12 {
    font-size: 12px;
}

.action-column {
    width: 100px;
}

.table-responsive {
    min-height: 200px !important;
}

/* Apply overflowX: clip only on large screens */
@media (min-width: 992px) {
    .table-responsive {
        overflow-x: clip !important;
    }
}

/* For small screens, no overflowX: clip */
@media (max-width: 991px) {
    .table-responsive {
        overflow-x: visible !important;
        /* or any other desired style */
    }
}

.field {
    margin-top: 8px;
}


/* Add this CSS to properly position the close icon */
.search-input-container {
    position: relative;
}

.search-input-container input {
    padding-right: 25px;
    /* Adjust the padding based on the icon size */
}

.search-input-container svg {
    position: absolute;
    top: 50%;
    right: 8px;
    /* Adjust the right position based on the icon size */
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d;
    /* Set the color for the close icon */
}

.has-validation {
    border: 1px solid red !important;
    border-radius: 4px !important;
}

.w-200 {
    width: 200px !important;
}

table td {
    vertical-align: middle;
    padding: 6px 6px !important;
}

table.dataTable td {
    padding: 3px 6px !important;
}

.vehicle.selected td,
.vehicle tr:hover td {
    background-color: #f7f7f7;
}

.rs-picker-toggle-wrapper {
    display: block !important;
}

.boxes .box-Total {
    background-color: #052c65;
    color: white;
    border-radius: 5px;
    opacity: 1;
}

.boxes .box-Active {
    background-color: rgb(25, 135, 84);
    color: white;
    border-radius: 5px;
    opacity: 1;
}

.boxes .box-Inactive {
    background-color: rgb(220, 53, 69);
    color: white;
    border-radius: 5px;
    opacity: 1;
}

.boxes .box-Pending {
    background-color: rgb(255, 193, 7);
    color: white;
    border-radius: 5px;
    opacity: 1;
}

.boxes .box-Lock {
    background-color: rgb(108, 117, 125);
    color: white;
    border-radius: 5px;
    opacity: 1;
}

.boxes .box-GracePeriod {
    background-color: rgb(13, 202, 240);
    color: white;
    border-radius: 5px;
    opacity: 1;
}

.map-search .select__control {
    z-index: inherit !important;
}


table.dataTable colgroup {
    display:none !important;   
    border: 10px solid red;
}