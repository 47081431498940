.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loader {
    border: 8px solid #368ef1; /* Loader color */
    border-top: 8px solid #368ef1; /* Loader color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



.spinner {
    width: 40px;
    height: 40px;
    border-left-style: solid;
    border-radius: 50%;
    animation: spinner-aib1d7 1.0499999999999998s infinite linear;
 }
 
 @keyframes spinner-aib1d7 {
    to {
       transform: rotate(360deg);
    }
 }