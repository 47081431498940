.status-badge {
    border-radius: 0;
    padding: 3px;
    font-size: 11px;
    font-weight: 500;
    justify-content: center;
    display: flex;
    min-width: 35px;
    align-items: center;
    width: fit-content;
    text-align: center;
}

.status-circle {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    display: inline-block;
}